import clsx from 'clsx'

// size: large, medium, sm, xs
// weight: bold, undefined
const TypographyLabels = ({
	children = <></>,
	size = 'medium',
	isBold = undefined,
	additionalClasses = '',
	...props
}: {
	children: React.ReactNode
	size?: 'xSmall' | 'small' | 'medium' | 'large'
	isBold?: true | false | undefined
	additionalClasses?: string
}) => {
	return (
		<span
			className={clsx(
				size === 'large' && isBold && 'font-abc text-labelLargeBold',
				size === 'large' && !isBold && 'font-abc text-labelLarge',
				size === 'medium' && isBold && 'font-abc text-labelMediumBold',
				size === 'medium' && !isBold && 'font-abc text-labelMedium',
				size === 'small' && isBold && 'font-abc text-labelSmallBold',
				size === 'small' && !isBold && 'font-abc text-labelSmall',
				size === 'xSmall' && isBold && 'font-abc text-labelXSmallBold',
				size === 'xSmall' && !isBold && 'font-abc text-labelXSmall',
				`${additionalClasses}`,
			)}
			{...props}
		>
			{children}
		</span>
	)
}

export default TypographyLabels
