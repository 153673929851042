import clsx from 'clsx'
import { TypographyLabel } from '../typography'
import { twMerge } from 'tailwind-merge'

const Button = ({
	variant = 'primary',
	size = 'medium',
	children,
	leftIcon,
	rightIcon,
	disabled = false,
	additionalClasses = '',
	...props
}: {
	variant?: 'primary' | 'secondary' | 'tertiary'
	size?: 'small' | 'medium' | 'large'
	children: React.ReactNode
	leftIcon?: React.ReactNode
	rightIcon?: React.ReactNode
	disabled?: boolean
	additionalClasses?: string
	onClick?: () => void
}) => {
	return (
		<button
			className={twMerge(
				clsx(
					// Button type specific styles
					variant === 'primary' &&
						'bg-uiTertiaryDark  text-textPrimaryDark active:bg-black disabled:bg-uiTertiaryDark/50 dark:bg-light dark:text-textPrimaryLight dark:active:bg-uiSecondaryDark active:dark:text-white disabled:dark:bg-white/50',
					variant === 'secondary' &&
						'border border-textPrimaryLight bg-transparent text-textPrimaryLight dark:border-textPrimaryDark dark:text-textPrimaryDark',
					variant === 'tertiary' &&
						'bg-transparent text-textPrimaryLight dark:text-textPrimaryDark ',
					!disabled &&
						variant === 'primary' &&
						'hover:bg-uiSecondaryDark hover:text-textPrimaryDark dark:hover:bg-uiPrimaryLight dark:hover:text-textPrimaryLight',
					!disabled &&
						variant === 'secondary' &&
						'hover:border-0 hover:bg-uiSecondaryDark hover:text-textPrimaryDark dark:hover:bg-uiTertiaryLight dark:hover:text-textPrimaryLight',
					!disabled && variant === 'tertiary' && 'hover:underline',
					// Disabled button type specific styles
					disabled === Boolean(true) &&
						variant === 'primary' &&
						'bg-uiTertiaryDark/50 dark:bg-white/50 ',
					disabled === Boolean(true) &&
						variant === 'secondary' &&
						'border-uiTertiaryDark/50 text-uiTertiaryDark/50 dark:border-textPrimaryDark/50 dark:text-textPrimaryDark/50',
					disabled === Boolean(true) &&
						variant === 'tertiary' &&
						'text-uiTertiaryDark/50 dark:text-textPrimaryDark/50',
					// Size specific styles
					size === 'large' && 'h-12 py-3',
					size === 'medium' && 'h-10 py-2',
					size === 'small' && 'h-8 py-2',
					// Caret specific styles
					size === 'large' && variant !== 'tertiary' && 'pl-8 pr-8',
					size === 'medium' && variant !== 'tertiary' && 'pl-6 pr-6',
					size === 'small' && variant !== 'tertiary' && 'pl-4 pr-4',
					// Shared Styles
					'flex items-center justify-center gap-x-1 rounded-md outline-0 ring-0',
					additionalClasses,
				),
			)}
			disabled={disabled}
			{...props}
		>
			{leftIcon && (
				<div
					className={clsx(
						'inline-flex items-center justify-center',
						size === 'small' ? 'h-4 w-4' : 'h-6 w-6',
					)}
				>
					{leftIcon}
				</div>
			)}
			<TypographyLabel size={size} isBold={true}>
				{children}
			</TypographyLabel>
			{rightIcon && (
				<div
					className={clsx(
						'inline-flex items-center justify-center',
						size === 'small' ? 'h-4 w-4' : 'h-6 w-6',
					)}
				>
					{rightIcon}
				</div>
			)}
		</button>
	)
}

export default Button
