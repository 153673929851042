import clsx from 'clsx'

// size: xLarge, large, medium, sm, xs
// weight: bold, undefined
const TypographyParagraph = ({
	children = <></>,
	size = 'medium',
	isBold = undefined,
	additionalClasses = '',
	...props
}: {
	children: React.ReactNode
	size?: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'
	isBold?: true | false | undefined
	additionalClasses?: string
}) => {
	return (
		<p
			className={clsx(
				size === 'xLarge' && isBold && 'font-abc text-paragraphXLargeBold',
				size === 'xLarge' && !isBold && 'font-abc text-paragraphXLarge',
				size === 'large' && isBold && 'font-abc text-paragraphLargeBold',
				size === 'large' && !isBold && 'font-abc text-paragraphLarge',
				size === 'medium' && isBold && 'font-abc text-paragraphMediumBold',
				size === 'medium' && !isBold && 'font-abc text-paragraphMedium',
				size === 'small' && isBold && 'font-abc text-paragraphSmallBold',
				size === 'small' && !isBold && 'font-abc text-paragraphSmall',
				size === 'xSmall' && isBold && 'font-abc text-paragraphXSmallBold',
				size === 'xSmall' && !isBold && 'font-abc text-paragraphXSmall',
				`${additionalClasses}`,
			)}
			{...props}
		>
			{children}
		</p>
	)
}

export default TypographyParagraph
